import { Modal, ModalProps } from 'antd';
import '../index.scss';
import CustomButton from '../../../../../components/buttons/CustomButton';

interface customModalProps extends ModalProps {
  className?: string;
  isOpen: boolean;
  setIsOpen?: (value: boolean) => void;
  handleCancelModal: () => void;
  handleSubmitDel: () => void;
}

const ModalConfirm = (props: customModalProps) => {
  const { isOpen, setIsOpen, className, handleCancelModal, handleSubmitDel, ...restProps } = props;

  return (
    <Modal
      title={'依頼をキャンセルしますか？'}
      className={`confirm-models ${className}`}
      open={isOpen}
      onCancel={handleCancelModal}
      closeIcon={false}
      {...restProps}
      footer={[]}
    >
      <CustomButton onClick={handleCancelModal} className="custom-button__cancel">
        キャンセル
      </CustomButton>
      <CustomButton className="custom-button__submit" onClick={handleSubmitDel}>
        登録
      </CustomButton>
    </Modal>
  );
};

export default ModalConfirm;
