import { Form, FormInstance, Pagination, Spin, Tabs } from 'antd';
import { SvgCoppy } from '../../../components/@svg/SvgCheck';
import TableWrap from '../../../components/TableWrap';
import CustomSearchInput from '../../../components/input/CustomSearchInput';
import './index.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import DatepickerCustom from '../../../components/DatepickerCustom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { QUERY_KEY, STATUS_ITEM_MANAGEMENT, TIME_FORMAT } from '../../../util/constants';
import { fileExportApi, storageItemApi } from '../../../apis';
import { CountForItemListResponse, StorageItem } from '../../../apis/client-axios';
import { formatDate, handleViewStatusItem, renderStatus } from '../../../util/helper';
import NotificationSuccess from '../../../components/notifications/NotificationSuccess';
import dayjs from 'dayjs';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import ButtonDetail from '../../../components/buttons/ButtonDetail';
import { ADMIN_ROUTE_PATH } from '../../../constants/router';
import { decode as decodeHtml } from 'he';
import CustomSelect from '../../../components/CustomSelect';
import { CheckIcon } from './@svg/tab';
import DowloadButton from '../../../components/buttons/DowloadButton';
import { saveAs } from 'file-saver';
import SvgFilterSort from '../../../components/@svg/SvgFilterSort';
import SvgArrowDown from '../../../components/@svg/SvgArrowDown';

type TColumnTable = {
  intl: IntlShape;
  tabActive: string;
  copyToClipboard: (value: string) => void;
  navigate: NavigateFunction;
  sort: TSort;
};

type TColumnSort = 'internalSku' | 'createdAt';
type TTypeSort = 'ASC' | 'DESC';
type TFilterSort = { columns: TColumnSort; sort: TTypeSort };
type TSort = {
  filterSort: TFilterSort;
  handleSort: (columns: TColumnSort) => void;
};

enum DataType {
  NEW_REQUEST_DATE = 'NEW_REQUEST_DATE',
  ARRIVAL_DATE = 'ARRIVAL_DATE',
  INSPECTION_DATE = 'INSPECTION_DATE',
  SHIP_TO_BUYER_REQUEST_DATE = 'SHIP_TO_BUYER_REQUEST_DATE',
  SHIP_DATE = 'SHIP_DATE',
}

enum RequestTab {
  ALL = 'ALL',
  NOT_SHIPPED = 'NOT_SHIPPED',
  IN_TRANSIT = 'IN_TRANSIT',
  WAITING_STOCK = 'WAITING_STOCK',
  IN_STOCK = 'IN_STOCK',
  ITEM_SHIP = 'ITEM_SHIP',
  ITEM_DISPOSAL = 'ITEM_DISPOSAL',
  COMPLETE = 'COMPLETE',
}

const ItemManagementTab = [
  RequestTab.ALL,
  RequestTab.NOT_SHIPPED,
  RequestTab.IN_TRANSIT,
  RequestTab.WAITING_STOCK,
  RequestTab.IN_STOCK,
  RequestTab.ITEM_SHIP,
  RequestTab.ITEM_DISPOSAL,
  RequestTab.COMPLETE,
];

type TPagination = {
  page: number;
  pageSize: number;
  total: number;
};
type TSearch = {
  from: string | undefined;
  to: string | undefined;
  fullTextSearch: string | undefined;
  dateType: string | undefined;
};
type TDataTable = {
  form: FormInstance;
  isLoadingItems: boolean;
  storageItems: StorageItem[];
  paginationInfo: TPagination;
  tabActive: string;
  searchItem: TSearch;
  setPagination: (paginationInfo: TPagination) => void;
  copyToClipboard: (value: string) => void;
  handleSearch: (value: string) => void;
  setSearchItem: (searchItem: TSearch) => void;
  handleKeyDown: (e: any) => void;
  disabledDateStart: (current: any) => boolean;
  disabledDateEnd: (current: any) => boolean;
  sort: TSort;
};
const n = (key: keyof TSearch) => key;

const getColumn = (data: TColumnTable): any => {
  const { intl, tabActive, copyToClipboard, navigate, sort } = data;
  return [
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      render: (_, record: StorageItem) => (
        <ButtonDetail
          content={intl.formatMessage({ id: 'item.detail' }) + ' '}
          buttonProps={{
            onClick: (e) => {
              if (e.ctrlKey || e.metaKey) {
                window.open(`${ADMIN_ROUTE_PATH.ITEM_MANAGEMENT_DETAIL}/${record?.id}`, '_blank');
              } else {
                navigate(`${ADMIN_ROUTE_PATH.ITEM_MANAGEMENT_DETAIL}/${record?.id}`);
              }
            },
          }}
        />
        // <NavLink to={`detail/${record.id}`}>
        //   <div className="cursor-pointer">
        //     <SvgVisible />
        //   </div>
        // </NavLink>
      ),
    },
    {
      title: () => {
        const columns: TColumnSort = 'internalSku';
        return (
          <div className="item-management__table-sort" onClick={() => sort.handleSort(columns)}>
            <div>BayPack管理番号</div>
            {sort.filterSort.columns === columns ? (
              <span className={`${sort.filterSort.sort === 'ASC' ? 'rotate-reverse' : ''}`}>
                <SvgArrowDown />
              </span>
            ) : (
              <span>
                <SvgFilterSort />
              </span>
            )}
          </div>
        );
      },
      dataIndex: 'sku',
      key: 'sku',
      width: '180px',
      render: (_, record: StorageItem) => (
        <button
          className="item-management__button-copy"
          onClick={() => {
            copyToClipboard(record?.internalSku);
          }}
        >
          <SvgCoppy />
          <p className="item-management__table-text">{record?.internalSku}</p>
        </button>
      ),
    },
    {
      title: intl.formatMessage({ id: 'item.customer' }),
      dataIndex: 'customer',
      key: 'customer',
      width: '200px',
      render: (_, record: StorageItem) => (
        <p className="item-management__table-text">
          <p className="item-management__table-text text-1-line">{record?.storageRequest?.user?.name}</p>
          <span>{record?.storageRequest?.user?.userCode}</span>
        </p>
      ),
    },
    {
      title: intl.formatMessage({ id: 'item.productName' }),
      dataIndex: 'productName',
      key: 'productName',
      width: '640px',
      render: (_, record: StorageItem) => (
        <p className="item-management__table-text text-2-line">{decodeHtml(record?.itemName || '')}</p>
      ),
    },
    {
      title: intl.formatMessage({ id: 'item.situation' }),
      dataIndex: 'status',
      key: 'status',
      width: '260px',
      render: (_, record: StorageItem) => (
        <>
          <p className="item-management__table-text">
            {intl.formatMessage({ id: `item.${handleViewStatusItem(renderStatus(record), true)}` })}
            {record.status === STATUS_ITEM_MANAGEMENT.IN_STORAGE && record.storageItemRack?.rack?.code
              ? `_${record.storageItemRack?.rack?.code}`
              : ''}
          </p>
          {tabActive === RequestTab.IN_TRANSIT && (
            <p className="item-management__table-text status-tracking">
              {record?.shippoShippingLabel?.trackingStatusDetails}
            </p>
          )}
        </>
      ),
    },
    {
      title: () => {
        const columns: TColumnSort = 'createdAt';
        const id =
          tabActive === RequestTab.ITEM_SHIP
            ? 'item.shipmentRequestedDate'
            : tabActive === RequestTab.ITEM_DISPOSAL
              ? 'item.disposalRequestedDate'
              : 'item.requestedDate';

        return (
          <>
            {id === 'item.requestedDate' ? (
              <div className="item-management__table-sort" onClick={() => sort.handleSort(columns)}>
                <div>{intl.formatMessage({ id })}</div>
                {sort.filterSort.columns === columns ? (
                  <span className={`${sort.filterSort.sort === 'ASC' ? 'rotate-reverse' : ''}`}>
                    <SvgArrowDown />
                  </span>
                ) : (
                  <span>
                    <SvgFilterSort />
                  </span>
                )}
              </div>
            ) : (
              intl.formatMessage({ id })
            )}
          </>
        );
      },
      dataIndex: 'requestedDate',
      key: 'requestedDate',
      width: '245px',
      render: (_, record: StorageItem) => {
        const requestDateShip = record.shipToBuyerItem?.shipToBuyerRequest?.createdAt
          ? formatDate(record.shipToBuyerItem?.shipToBuyerRequest?.createdAt ?? '')
          : record.shipBackSellerRequest?.createdAt
            ? formatDate(record.shipBackSellerRequest?.createdAt)
            : '';
        const requestDate =
          tabActive === RequestTab.ITEM_SHIP ? requestDateShip : formatDate(record?.storageRequest?.createdAt ?? '');
        return <p className="item-management__table-text">{requestDate}</p>;
      },
    },
  ];
};
const TableAndFields = ({ ...props }: TDataTable) => {
  const {
    form,
    isLoadingItems,
    storageItems,
    paginationInfo,
    tabActive,
    searchItem,
    setPagination,
    copyToClipboard,
    setSearchItem,
    handleSearch,
    disabledDateStart,
    disabledDateEnd,
    sort,
  } = props;
  const intl = useIntl();
  const navigate = useNavigate();

  const tabOptions = {
    ALL: [0, 1, 2, 3, 4],
    NOT_SHIPPED: [0],
    IN_TRANSIT: [0],
    WAITING_STOCK: [0, 1],
    IN_STOCK: [0, 1, 2],
    ITEM_SHIP: [0, 1, 2, 3],
    ITEM_DISPOSAL: [0, 1, 2],
    COMPLETE: [0, 1, 2, 3, 4],
  };

  const filterOptionsByTab = () => {
    const indices = tabOptions[tabActive] || [];
    return mapOption.filter((option, index) => indices.includes(index));
  };
  const mapOption: any = [
    {
      value: DataType.NEW_REQUEST_DATE,
      label: (
        <div className="h-100 d-flex align-items-center">
          <span className="font-base font-weight-500 font-size-16 color-514B4D" style={{ color: '#514B4D' }}>
            新規依頼日
          </span>
          {searchItem.dateType === DataType.NEW_REQUEST_DATE && <CheckIcon />}
        </div>
      ),
    },
    {
      value: DataType.ARRIVAL_DATE,
      label: (
        <div className="h-100 d-flex align-items-center">
          <span className="font-base font-weight-500 font-size-16 color-514B4D" style={{ color: '#514B4D' }}>
            到着日
          </span>
          {searchItem.dateType === DataType.ARRIVAL_DATE && <CheckIcon />}
        </div>
      ),
    },
    {
      value: DataType.INSPECTION_DATE,
      label: (
        <div className="h-100 d-flex align-items-center">
          <span className="font-base font-weight-500 font-size-16 color-514B4D" style={{ color: '#514B4D' }}>
            検品日
          </span>
          {searchItem.dateType === DataType.INSPECTION_DATE && <CheckIcon />}
        </div>
      ),
    },
    {
      value: DataType.SHIP_TO_BUYER_REQUEST_DATE,
      label: (
        <div className="h-100 d-flex align-items-center">
          <span className="font-base font-weight-500 font-size-16 color-514B4D" style={{ color: '#514B4D' }}>
            発送依頼日
          </span>
          {searchItem.dateType === DataType.SHIP_TO_BUYER_REQUEST_DATE && <CheckIcon />}
        </div>
      ),
    },
    {
      value: DataType.SHIP_DATE,
      label: (
        <div className="h-100 d-flex align-items-center">
          <span className="font-base font-weight-500 font-size-16 color-514B4D" style={{ color: '#514B4D' }}>
            発送日
          </span>
          {searchItem.dateType === DataType.SHIP_DATE && <CheckIcon />}
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="d-flex align-items-center justify-content-start">
        <Form form={form} className="d-flex align-items-center">
          <div className="d-flex align-items-center gap-8 select-option-day">
            <Form.Item name={n('dateType')} className="m-b-0">
              <CustomSelect
                // disabled={!filter.userId}
                defaultValue={DataType.NEW_REQUEST_DATE}
                options={filterOptionsByTab()}
                value={searchItem.dateType}
                onChange={(value) => setSearchItem({ ...searchItem, dateType: value })}
              />
            </Form.Item>
            <span>:</span>
          </div>
          <div className="d-flex align-items-center m-r-80 gap-8">
            {/* <span className="font-weight-500 font-size-16 color-514B4D ">
              {intl.formatMessage({
                id:
                  tabActive === RequestTab.ITEM_SHIP
                    ? 'item.shipmentRequestedDate'
                    : tabActive === RequestTab.ITEM_DISPOSAL
                      ? 'item.disposalRequestedDate'
                      : 'item.requestedDate',
              })}
            </span> */}
            <div className="d-flex align-items-center gap-2">
              <Form.Item name={n('from')} className="m-b-0">
                <DatepickerCustom
                  datePickerProp={{
                    onChange: (_, dateString: string) => {
                      setSearchItem({ ...searchItem, from: dateString ? (dateString as string) : undefined });
                    },
                    disabledDate: disabledDateStart,
                  }}
                />
              </Form.Item>
              <span className="item-management__range-date">~</span>
              <Form.Item name={n('to')} className="m-b-0">
                <DatepickerCustom
                  datePickerProp={{
                    onChange: (_, dateString: string) => {
                      setSearchItem({ ...searchItem, to: dateString ? (dateString as string) : undefined });
                    },
                    disabledDate: disabledDateEnd,
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item name={n('fullTextSearch')} className="m-b-0">
            <CustomSearchInput
              className="font-weight-500 font-size-16 switch-false item-management__search-input"
              placeholder={intl.formatMessage({ id: 'item.searchPlaceholder' })}
              // onKeyDown={handleKeyDown}
              onChange={(e) =>
                e?.target?.value
                  ? handleSearch(e?.target?.value)
                  : setSearchItem({ ...searchItem, fullTextSearch: undefined })
              }
            />
          </Form.Item>
        </Form>
      </div>
      <div className="m-t-24">
        <TableWrap
          data={storageItems}
          isLoading={isLoadingItems}
          className="item-management__table ebay__scroll-bar-custom-horizon"
          columns={getColumn({ intl, tabActive, copyToClipboard, navigate, sort })}
          isScroll
          scrollValue={{ x: 1366, y: '65vh' }}
        />
      </div>
      <div className="item-management__table-pagination-container">
        <Pagination
          current={paginationInfo.page}
          pageSize={paginationInfo.pageSize}
          total={paginationInfo.total}
          showSizeChanger
          pageSizeOptions={[20, 50, 100]}
          locale={{ items_per_page: intl.formatMessage({ id: 'item.lines' }) }}
          onChange={(current: number, pageSize: number) => {
            setPagination({ ...paginationInfo, page: current, pageSize });
          }}
        />
      </div>
    </>
  );
};
const handleGetCountOfTab = (status: string, dataCount: CountForItemListResponse) => {
  if (!!dataCount) {
    switch (status) {
      // case RequestTab.ALL:
      //   return dataCount.all;
      case RequestTab.NOT_SHIPPED:
        return dataCount.notShipped;
      case RequestTab.IN_TRANSIT:
        return dataCount.inTransit;
      case RequestTab.WAITING_STOCK:
        return dataCount.waitingStock;
      case RequestTab.IN_STOCK:
        return dataCount.inStock;
      case RequestTab.ITEM_SHIP:
        return dataCount.itemShip;
      case RequestTab.ITEM_DISPOSAL:
        return dataCount.itemDisposal;
      // case RequestTab.COMPLETE:
      //   return dataCount.complete;
      default:
        return '';
    }
  } else {
    return '';
  }
};
const ItemManagement: React.FC = () => {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [tabActive, setTabActive] = useState<string>(searchParams.get('tab') ?? RequestTab.ALL);
  const [listItems, setListItems] = useState<StorageItem[]>([]);
  const [pagination, setPagination] = useState<TPagination>({
    pageSize: 20,
    page: 1,
    total: 0,
  });
  const [searchItem, setSearchItem] = useState<TSearch>({
    from: undefined,
    to: undefined,
    fullTextSearch: undefined,
    dateType: DataType.NEW_REQUEST_DATE,
  });
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const { data: countTab, refetch: refetchCountTab } = useQuery({
    queryKey: [QUERY_KEY.ITEMS_COUNT],
    queryFn: () => storageItemApi.storageItemControllerCountForItemList(),
    staleTime: 1000,
  });
  const [filterSort, setFilterSort] = useState<TFilterSort>({ columns: null, sort: null });

  const getTypeSort = (col: TColumnSort): TTypeSort =>
    filterSort.columns !== col ? 'ASC' : filterSort.sort === null ? 'ASC' : filterSort.sort === 'ASC' ? 'DESC' : null;
  const handleSort = (columns: TColumnSort, sort = getTypeSort(columns)) => {
    const sortNew: TFilterSort = { columns, sort };
    const sortNull: TFilterSort = { columns: null, sort: null };
    setFilterSort(sort ? sortNew : sortNull);
  };

  const { data: listItemsManagement, isLoading: isLoadingItems } = useQuery({
    queryKey: [
      QUERY_KEY.LIST_GROUP_CHAT,
      tabActive,
      pagination.pageSize,
      pagination.page,
      searchItem.fullTextSearch,
      searchItem.from || searchItem.to ? searchItem.dateType : null,
      searchItem.from,
      searchItem.to,
      filterSort,
    ],
    queryFn: () =>
      storageItemApi.storageItemControllerItemManagementAdmin(
        pagination.page,
        pagination.pageSize,
        filterSort.columns && filterSort.sort ? `${filterSort.columns}-${filterSort.sort}` : null,
        searchItem.fullTextSearch,
        searchItem.dateType as DataType,
        searchItem.from,
        searchItem.to,
        undefined,
        tabActive as RequestTab,
      ),
    staleTime: 1000,
  });

  useEffect(() => {
    if (searchParams && searchParams.get('tab')) {
      setTabActive(searchParams.get('tab'));
    } else {
      setTabActive(RequestTab.ALL);
    }
  }, [searchParams]);

  useEffect(() => {
    if (listItemsManagement?.data?.content) {
      const listRacksData: StorageItem[] = listItemsManagement.data.content;
      const total: number = listItemsManagement.data.total;
      setListItems(listRacksData ?? []);
      if (total !== pagination.total) {
        setPagination({ ...pagination, total });
      }
    }
  }, [listItemsManagement]);

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value).then(
      () => {
        NotificationSuccess({ content: intl.formatMessage({ id: 'common.copy' }) });
      },
      (err) => {
        NotificationSuccess({ content: err });
      },
    );
  };

  const debounceSearch = useCallback(
    (value: string) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        setSearchItem({ ...searchItem, fullTextSearch: value || undefined });
      }, 500);
    },
    [timeout],
  );

  const handleChangeSearch = useCallback(
    (value: string) => {
      debounceSearch(value);
    },
    [searchItem.fullTextSearch],
  );

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleChangeSearch(event.target.value);
    }
  };

  const handleSearch = debounce((value) => {
    if (!!value?.trim()?.toString()) {
      setSearchItem({ ...searchItem, fullTextSearch: value.trim() });
    }
  }, 500);

  const disabledDateEnd = (current) => {
    if (!!searchItem.from) {
      return current && current < dayjs(searchItem.from).startOf('day');
    } else {
      return undefined;
    }
  };
  const disabledDateStart = (current) => {
    if (!!searchItem.to) {
      return current && current > dayjs(searchItem.to).startOf('day');
    } else {
      return undefined;
    }
  };
  const exportCsv = useMutation({
    mutationKey: ['exportCsv'],
    mutationFn: () => fileExportApi.fileExportControllerExportCsv(tabActive as any, { responseType: 'blob' }),
    onSuccess: ({ data }) => {
      const fileName = dayjs().format(TIME_FORMAT.MESSAGE_TIME);
      saveAs(data as any, `${intl.formatMessage({ id: 'item.title' })}_${fileName.replace(' ', '_')}.csv`);
    },
  });
  const handleDownload = () => exportCsv.mutate();
  const items = ItemManagementTab.map((item, index) => {
    return {
      label: (
        <p className="item-management__title-tabs">
          <span className="item-management__title-name">{intl.formatMessage({ id: `item.${item}` })}</span>
          <span className="item-management__title-number small-number">
            {handleGetCountOfTab(item, countTab?.data)}
          </span>
        </p>
      ),
      key: item,
      disabled: false,
      children: (
        <TableAndFields
          form={form}
          isLoadingItems={isLoadingItems}
          storageItems={listItems}
          paginationInfo={pagination}
          tabActive={tabActive}
          searchItem={searchItem}
          setPagination={setPagination}
          copyToClipboard={copyToClipboard}
          setSearchItem={setSearchItem}
          handleKeyDown={handleKeyDown}
          disabledDateStart={disabledDateStart}
          disabledDateEnd={disabledDateEnd}
          handleSearch={handleSearch}
          sort={{ filterSort, handleSort }}
        />
      ),
    };
  });
  return (
    <div className="item-management">
      <div className="item-management__header">
        <span className="color-title font-weight-700 font-size-28">{intl.formatMessage({ id: 'item.title' })}</span>
      </div>
      <div className="item-management__btn-download">
        <DowloadButton onClick={handleDownload} />
        {exportCsv.isPending && (
          <div className="loading">
            <Spin />
          </div>
        )}
      </div>
      <Tabs
        activeKey={tabActive}
        defaultActiveKey={searchParams.get('tab') || RequestTab.ALL}
        centered
        tabPosition={'top'}
        onChange={(e: string) => {
          setTabActive(e);
          setSearchParams({ ['tab']: e });
          setPagination({
            pageSize: 20,
            page: 1,
            total: 0,
          });
          setSearchItem({
            to: undefined,
            from: undefined,
            fullTextSearch: undefined,
            dateType: DataType.NEW_REQUEST_DATE,
          });
          form.resetFields();
          refetchCountTab();
        }}
        items={items}
      />
    </div>
  );
};

export default ItemManagement;
